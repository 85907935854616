import React, { useEffect, useState } from "react";
import "./index.css";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { purple, deepOrange, blueGrey, brown } from "@mui/material/colors";
import ArrowIcon from "../../../assets/images/ArrowIcon.png";
import ArrowIconmobile from "../../../assets/images/round-arrow.png";
import allRoute from "../../../utils/constants";
import { AdminQuillEditor, QuillEditor } from "../../../utils/quill";
import Footer from "../Footer";
import Navbar from "../../Navigation";
import MenuIcon from "@mui/icons-material/Menu";
import { Html,Css,Firebase,React2, GitHub } from "../../../assets";
export default function Video_Screen() {
  const [videoindex, setvideoIndex] = useState(0);
  const [data, setData] = useState();
  // const reverseData = data;
  const [isVisible, setisVisible] = useState(false)
  const { id } = useParams();
  const routeName = id.split("-")[0];
  const icon=routeName=="html"?Html:routeName=="css"?Css:routeName=="firebase"?Firebase:routeName=="react"?React2:routeName=="github"?GitHub:null
  const listbgcolor=routeName=="html"?"#FF651E":routeName=="css"?"#005CF0":routeName=="react"?"#8DD6E4":routeName=="firebase"?"#D7B138":null
  const navigation = useNavigate();

  const toggleVisibility = () => {
    setisVisible(!isVisible);
  };

  useEffect(() => {
    handleCheckNavigation()
  }, []);
  const handleCheckNavigation = (paramRoute) => {
    console.log("🚀 ~ file: Video_Screen.js:24 ~ handleCheckNavigation ~ paramRoute:", paramRoute)
    const allData = allRoute[routeName]


    let isPageFound = false;
    if (allData) {
      allData.forEach((page, ind) => {
        if (page.url === (paramRoute || id)) {
          setvideoIndex(ind);
          isPageFound = true;
        }
      });
      if (isPageFound) return setData(allData);
    }

    navigation("/", { replace: true });
  }

  const handleSelect = (val,index) => {
    navigation("/" + val?.url, { replace: true })
    setvideoIndex(index)
    handleCheckNavigation(val?.url)
    setisVisible(!isVisible)
  }

  const Righthandler = () => {
    navigation(`/${data[videoindex + 1].url}`, { replace: true });
    setvideoIndex(data?.length - 1 > videoindex ? videoindex + 1 : videoindex);
  };
  const Lefthandler = () => {
    setvideoIndex(videoindex - 1);
    navigation(`/${data[videoindex - 1]?.url}`, { replace: false });
  };

  if (!data) return <></>;

  return (
    <div
      id="Main_VedioIframeDiv"
      className="VedioIframDiv"
      style={{
        background: `linear-gradient(209.21deg, rgb(106, 27, 154) 13.57%, gray)`,
      }}
    >
      <div className="position-fixed-mobile">
        <div className="container">
          <Navbar animate={false} />
        </div>
        <div style={{marginTop:10}} className="Main_VedioIframeDiv" >
          <img
            src={ArrowIcon}
            className="Video_arrow_left"
            style={{ opacity: videoindex == 0 ? 0.2 : 1 }}
            onClick={videoindex == 0 ? null : Lefthandler}
            aria-disabled
          />
          <iframe
            className="_Ifram"
            src={`https://www.youtube.com/embed/${data[videoindex].videoId}`}
            title={"Html Video"}
            allowFullScreen
          ></iframe>
          <img
            src={ArrowIcon}
            style={{ opacity: videoindex == data?.length - 1 ? 0.2 : 1 }}
            onClick={videoindex == data?.length - 1 ? null : Righthandler}
            className="Video_arrow_right"
          />
        </div>
      </div>
      <div className="video-fixed-margin">
        <div className="container">
          <p
            className="Video_Heading"
            style={{ textDecorationLine: "underline" }}
          >
            {data[videoindex]?.heading}
          </p>
          <p className="Video_Discription"> {data[videoindex]?.description}</p>
        </div>


        {/* =====================Blog work========================= */}

        
        {/* <div className="Blog-div">
          <div className="Blog-div" style={{ position: "relative" }}>
            {data[videoindex]?.blog ? (
              <QuillEditor
                readOnly={true}
                value={data[videoindex]?.blog}
                sx={{ height: "fit-content", color: "white" }}
              />
            ) : null}
          </div>
          <div className="Blog-div">
          <AdminQuillEditor
          
            // readOnly={true}
            onChange={(val) => {setBlog(val);console.log(val)}}
            value={blog}
            sx={{ height: "fit-content", color: "white"}}
          />
        </div>
        </div> */}
      </div>

      {/* =============Mobile arrows================ */}

      <div className="mobile-View-Arrows">
        <img
          src={ArrowIconmobile}
          className="Video_arrow_left arrowmobile"
          style={{ opacity: videoindex == 0 ? 0.2 : 1 }}
          onClick={videoindex == 0 ? null : Lefthandler}
        />
        <img
          src={ArrowIconmobile}
          style={{ opacity: videoindex == data?.length - 1 ? 0.2 : 1 }}
          onClick={videoindex == data?.length - 1 ? null : Righthandler}
          className="Video_arrow_right arrowmobile"
        />
      </div>

    

      <div className="Videos-List-Div">
        <img
        className="VideoListIcon"
          src={icon}
          style={{ height: 80, width: 80, alignSelf: "end" }}
          onClick={toggleVisibility}
        />

<div className={isVisible ? 'Videos-List-Div-Show' : 'conditionStyle'}>
  <div style={{ position: "relative"}}>
    <div className="list-sticky-header" style={{ height: 50, backgroundColor: listbgcolor, position: "sticky", top: 0, zIndex: 3, width: "100%",display:"flex",justifyContent:"center",alignItems:"center",padding:10}}>
      {/* The div which should remain fixed when scrolling */}
      <p style={{fontWeight:"bold",margin:0,fontSize:"0.7rem",color:"white"}}>{routeName=="html"?"Html Course Structure , tags, images":routeName=="css"?"Css Course flex, flexbox, position, margin":routeName=="react"?"React Course components Hooks, functions":routeName=="firebase"?"Firebase Setup react and Html":null}...</p>

    </div>
    <div className="inner-scrollable-div">
      {data?.map((val, index) => {
        const reversedIndex = data.length - 1 - index;
        return (
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>
            <p style={styles.text_2}>{index + 1}</p>
            <div
              key={val.id}
              style={styles.eachvideodiv}
              onClick={() => handleSelect(val, reversedIndex)}
            >
              <p style={styles.text}>{val.heading}</p>
            </div>
          </div>
        );
      })}
    </div>
  </div>
</div>

      </div>

      <div className="container" id="footer2">
        <Footer />
      </div>
    </div>
  );
}
const styles = {
  eachvideodiv: {
    width: "95%",
    minHeight: 50,
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    margin: 8,
    padding: 8,
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  text:{ margin: 0, color: "white", padding: "0px", fontFamily: "Arial Blacked", fontSize: "0.8rem", textAlign: "center", },
  text_2:{ margin: 0, color: "white", padding: "0px", fontFamily: "Arial Blacked", fontSize: "0.8rem", textAlign: "center", padding: "3px 10px",borderRadius:"50%",backgroundColor:"rgba(0, 0, 0, 0.2)"}
  
};
