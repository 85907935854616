import { Css, Firebase, Html, Javascript,React2, } from "../assets";
import { react } from "./react-data"; 
import { firebase } from "./firebase-data";
import { html } from "./html-data";
import { css } from "./css-data";
import {github} from "./github-data"
import {javascript} from "./javascript-data"
import {reactnative} from "./react-native-data"
  


  
let javascripCourseHeading={
  Heading:"JavaScript Mastery",
  discription:"Master JavaScript to create dynamic and interactive web applications."
}
let reactnativeCourseHeading={
  Heading:"React Native Essentials",
  discription:" Harness JavaScript to develop cross-platform mobile apps using React Native"
}
let fireabaseCourseHeading={
  Heading:"Firebase Fundamentals",
  discription:"Utilize JavaScript and Firebase to leverage real-time data and powerful backend services"
}
let gitHubCourseHeading={
  Heading:"GitHub Essentials",
  discription:"Optimize your development workflow with JavaScript, version control, and collaboration using GitHub"
}
let cssCourseHeading = {
  Heading: "CSS Styling Mastery",
  discription: "Learn advanced CSS techniques to create stunning and responsive web designs"
};
let htmlCourseHeading = {
  Heading: "HTML Fundamentals",
  discription: "Master the fundamentals of HTML for building web pages and applications"
};


 export const courseContents={
    javascripCourseHeading,
    reactnativeCourseHeading,
    fireabaseCourseHeading,
    gitHubCourseHeading,
    cssCourseHeading,
    htmlCourseHeading
  }
  const allRoute = {
    react,
    firebase,
    html,
    css,
    github,
    javascript,
    reactnative
  }

  export default allRoute;


