import React, { useEffect, useState } from "react";
import Navbar from "../../Navigation";
import "./index.css";
import { Button, CoursCard } from "../../../component";
import CoursCard2 from "../../../component/CoursesCard2/CoursesCard2";
import cardpic from "../../../assets/images/courses-cardimg2.png";
import ayanpic from "../../../assets/images/ayan.png";
import PurchaseButton from "../../../component/Button";
import Footer from "../Footer";
import { useNavigate, useParams } from "react-router-dom";
import allRoute, { courseContents } from "../../../utils/constants";
import { Css, Firebase, GitHub, Html, Javascript, React2 } from "../../../assets";
import { firebase } from "../../../utils/firebase-data";
function Courses() {


  const navigation = useNavigate();
  const { id } = useParams();
  const routeName = id?.split("-")[0];
  useEffect(() => {
    handleCheckNavigation();
  }, []);
  const [data, setData] = useState([]);
  const handleCheckNavigation = (paramRoute) => {
    console.log(
      "🚀 ~ file: Video_Screen.js:24 ~ handleCheckNavigation ~ paramRoute:",
      paramRoute
    );
    const allData = allRoute[routeName];
    console.log("🚀 ~ file: Courses.js:30 ~ handleCheckNavigation ~ allRoute:", Object.keys(allRoute).map((val)=>val))

    let isPageFound = false;
    if (allData) {
        Object.keys(allRoute).map((val)=>{
          if (routeName==val){
            console.log("routeName",routeName)
            console.log("val",val)
            isPageFound=true
          }
        })
      if (isPageFound) return setData(allData);
    }
    navigation("/", { replace: true });
  };
  
  const icon=routeName=="html"?Html:routeName=="css"?Css:routeName=="firebase"?Firebase:routeName=="reactnative"?React2:routeName=="javascript"?Javascript:routeName=="github"?GitHub:null
  let {Heading,discription}=routeName=="html"?courseContents.htmlCourseHeading:routeName=="css"?courseContents.cssCourseHeading:routeName=="firebase"?courseContents.fireabaseCourseHeading:routeName=="reactnative"?courseContents.reactnativeCourseHeading:routeName=="javascript"?courseContents.javascripCourseHeading:routeName=="github"?courseContents.gitHubCourseHeading:{Heading:null,discription:null}
  return (
    <div className="Courses" id="Course">
      <div className="container CourseNavBar" >
        <Navbar />
        </div>
      <div className="Courses-Contents-div container">
        <CoursCard2 colors={"#62D9FB"} pic={icon} />
        <div
        className="lakdlkj" 
          style={{
            width: 380,
            height: 430,
            marginLeft: 20,
            display: "flex",
            justifyContent: "space-around",
            flexDirection: "column",
          }}
        >
          <p className="Courses-Heading">
            {Heading?Heading:null}
          </p>
          <p className="discriptionTxT">33 SECTIONS - 7 HOURS OF VIDEO</p>
          <p className="discriptionTxT" style={{ opacity: 1, width: 300 }}>
           {discription?discription:null}
          </p>
          <div  style={{ display: "flex", alignItems: "flex-start" }}>
            <img
              src={ayanpic}
              style={{ height: 33, width: 33, borderRadius: 10 }}
            />
            <p
              className="discriptionTxT"
              style={{ marginTop: 8, marginLeft: 10 }}
            >
              TAUGHT BY AYAN{" "}
            </p>
          </div>
          <PurchaseButton title={"GET PRO ACCESS"} subtitle={"Rs 2.5k/month"} />
        </div>
      </div>
      {data?.length?<div className="container" style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
      <p className="discriptionTxT" style={{textAlign:"center",marginTop:50,fontSize:"1.7rem"}}>{data?.length} TOPICS</p>
      <p style={{color:"white",textAlign:"center",alignSelf:"center",maxWidth:500,fontSize:"0.8rem"}} >{"All techniques are explained step-by-step, in a beginner-friendly format so that you can easily follow in a cohesive way."}</p>
      </div>:null}
      <div className="Courses-Video-div container">
        {data.length? 
            data?.map((val, index) => (
          <div
            onClick={() => {
              navigation("/" + val?.url, { replace: true });
            }}
            className="number-list-container"
            style={{
              cursor: "pointer",
              fontSize: "0.8rem",
              flexDirection: "row",
              display: "flex",
              padding: 10,
              justifyContent: "space-between",
              margin: 3,
              gap: 2,
              alignItems: "start",
              width: "100%",
            }}
            key={index}
          >
            <div style={{ display: "flex" }}>
              <img src={val.icon} className="data-icon-image" />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <p
                  className="num-heading"
                  style={{
                    margin: 0,
                    color: "white",
                    fontFamily: "Arial Black",
                  }}
                >
                  {val.heading}
                </p>
                <p
                  className="num-discription"
                  style={{
                    margin: 0,
                    color: "white",
                    opacity: 0.8,
                  }}
                >
                  {val.description}
                </p>
              </div>
            </div>
            <h6
              style={{
                padding: 3,
                borderRadius: 3,
                backgroundColor: "grey",
                margin: 0,
                color: "white",
                fontFamily: "Arial Black",
              }}
            >
              {val?.time}
            </h6>
          </div>
        ))
      :<p className="Courses-Heading">Coming Soon...</p>}
   
      </div>
      <div className="container">
        <Footer />
      </div>
    </div>
  );
}

export default Courses;
