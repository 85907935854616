import React from 'react';
import "./index.css";
// import coursescardimg from "../../assets/coursesCardImage.png";
import star from "../../assets/images/star.svg";
import swificon from "../../assets/images/swiftui-logo.svg";
import ayan from "../../assets/images/ayan.png"
const CoursCard2 = ({ bottom, left,pic,colors,Heading,Discription,isinstructor }) => {
  return (
    <div className='Courses_card2_main' style={{left:left?left:0,bottom:bottom?bottom:0, }}>
      <div className='CoursesCard2' style={{background:`linear-gradient(194deg, rgb(235 98 251 / 48%) 13.57%, rgb(0, 24, 85) 98.38%)`}} >
        {/* <img src={swificon} style={{ height: 20, width: 20, position: "absolute", top: 20, backgroundColor: "rgba(0, 0, 0, 0.2)", padding: 3, borderRadius: 10, left: 200 }}/> */}
        
        <div className='Courses_card2_img-parent2'>
         <div className='Courses_card2_img-parent1'>
          <img src={pic} className='Courses_card2_img' />
          </div>
          </div>
        <div style={{justifyContent:"center",display:"flex",flexDirection:"column",alignItems:"center"}}>
        <p className='Courses-card2-Name'>{Heading}</p>
        <p className='Courses-card2-discription'>{Discription}</p>
        </div>
        {/* <img src={star} className='Star-img' /> */}
      </div>
    </div>
  );
}

export default CoursCard2;

