import { GitHub } from "../assets";

export const github = [
    {
        videoId: "E2Cj5bSJeZU",
        time: " 20:12",
        icon: GitHub,
        url: "github-tuturial-for-beginner",
        heading: "Git & GitHub Tutorial For Beginners ",
        description: "Learn the essentials of Git and GitHub with this beginner-friendly tutorial.",
      },
]