import React from 'react';
import './index.css';
import { Image } from '../../assets';
import MenuIcon from '@mui/icons-material/Menu';
// import TextAnimation from '../screens/textanimation';
import { useNavigate } from 'react-router-dom';

const Navbar = ({animate}) => {
  const navigation = useNavigate();

  const goToDashBoard = (elementId) => {
    navigation('/');
    setTimeout(() => {
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 500);
  };

  return (
    <div
      // data-aos-offset="200"
  
      className="navbar">
      <div className="container">
        <a className="navbar-brand" href="#">
          <div className='Navaling'>
            <img
              src={Image}
              alt="On Img"
              className="LogoStyles2"
              onClick={() => {
                navigation('/');
              }}
            />
            <p
              //   ref={textRef}
              className="text-animation text-with-shadow"
              style={{ fontSize: 20, color: '#FFF', fontWeight: 'bolder', marginLeft: -30 }}
            >
              {/* Ayan Tech */}
            </p>
          </div>
        </a>

<div className='Laptop-view-navbar' style={{width:"60%",justifyContent:"space-around"}}>
              <a className="nav-link active text-with-shadow"
              // onClick={() => goToDashBoard('')}
              // href="#"
              >
                Home
              </a>
              <a 
              className="nav-link active text-with-shadow" 
              // onClick={() => goToDashBoard('aboutus')}
              // href="#aboutus"

              >
                About Us
              </a>
              <a
                className="nav-link active text-with-shadow"
                // onClick={() => goToDashBoard('ourservices')}
                // href="#ourservices"
              >
                Our Services
              </a>
              <a className="nav-link active text-with-shadow"
              //  onClick={() => goToDashBoard('')}
               
                >
                Portfolio
              </a>
              <a className="nav-link active text-with-shadow"
              //  onClick={() => goToDashBoard('footer')}
              //  href="#footer"
                >
                Contact Us
              </a>
         </div>





                   {/* Mobile View=============================> */}


        <li className="navbar-toggler dropdown width mobile-view-dropdown" aria-expanded="false">
          <a className="nav-link menue-Icon" href="#" role="button" data-bs-toggle="dropdown">
            <MenuIcon color="#FFF" />
          </a>
          <ul className="dropdown-menu MainStyle">
            <li>
              <a className="dropdown-item" href="#" onClick={() => goToDashBoard('')}>
                Home
              </a>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li>
            <li>
              <a className="dropdown-item" href="#aboutus" onClick={() => goToDashBoard('aboutus')}>
                About Us
              </a>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li>
            <li>
              <a className="dropdown-item" href="#ourservices" onClick={() => goToDashBoard('ourservices')}>
                Our Services
              </a>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li>
            <li>
              <a className="dropdown-item"onClick={() => goToDashBoard('')}
              href="#">
                Portfolio
              </a>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li>
            <li>
              <a className="dropdown-item" 
              href="#footer" 
              onClick={() => goToDashBoard('footer')}
              >
                Contact Us
              </a>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li>
          </ul>
        </li>
     
      </div>
    </div>
  );
};

export default Navbar;
