import React, { useEffect, useState } from 'react';
import "./index.css";
// import coursescardimg from "../../assets/coursesCardImage.png";
import star from "../../assets/images/star.svg";
import ayan from "../../assets/images/ayan.png"
import { useNavigate,  } from 'react-router-dom';

const CoursCard = ({ bottom, left,pic,colors,Heading,Discription,isinstructor,courseName,isnotcard }) => {
  const navigation=useNavigate()

  const handleCoursePress=()=>{
navigation(`/courses/${courseName}`)

  setTimeout(() => {
    const element = document.getElementById("Course");
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, 500);


  }


  
  return (
    <div className='Courses_card_main' style={{left:left?left:0,bottom:bottom?bottom:0, }} onClick={!isnotcard?handleCoursePress:null}>
      <div className='CoursesCard' style={{background:`linear-gradient(209.21deg, ${colors} 13.57%, rgb(0, 24, 85) 98.38%)`}} >
        {/* <img src={swificon} style={{ height: 20, width: 20, position: "absolute", top: 20, backgroundColor: "rgba(0, 0, 0, 0.2)", padding: 3, borderRadius: 10, left: 200 }}/> */}
        <div className='CoursesCardImg-div'>
          <img src={pic} className='Courses_card_img' />
          <div className='Course_Card_Icon' style={{ display: bottom?"flex":"none", flexDirection: "column",justifyContent:"space-around",alignItems:"center"}}>
          {!isinstructor?
          <> <img src={ayan} className='icon-div' id='icon_div' />
            <img src={pic} className='icon-div' />
            </>:null}
          </div>
        </div>
        <div style={{justifyContent:"center",display:"flex",flexDirection:"column",alignItems:"center"}}>
        <p className='Courses-card-Name'>{Heading}</p>
        <p className='Courses-card-discription'>{Discription}</p>
        </div>
        <img src={star} className='Star-img' />
      </div>
    </div>
  );
}


export default CoursCard;
