import React from 'react'
import "./index.css"
import { BookShape2 } from '../../../component'
function Subscription() {
  return (
    <div className='Subscriptions'>
      <h1 style={{fontWeight:"bold"}}>Ready to start?</h1>
      <BookShape2 />
    </div>
  )
}

export default Subscription
